import React, { useEffect } from "react"
import { useSessionContext, useSupabaseClient } from "@supabase/auth-helpers-react"
import { useRouter } from "next/router"
import { differenceInCalendarDays } from "date-fns"

import { useProfile } from "@contexts/profile/hook"
import Loading from "@components/Loading"

const Login = () => {
  const { replace, query } = useRouter()
  const supabase = useSupabaseClient()
  const { session, isLoading } = useSessionContext()
  const { profile } = useProfile()

  const { params, id } = query

  useEffect(() => {
    //isLoading; Bu kontrol ile Session'ın load olmasını sağlıyoruz.
    if (isLoading) {
      return
    }
    const isForcedAuth = session?.user.user_metadata?.auth_type === "forced"
    if (params) {
      const base64String = params || ("" as string)
      const decodedBase64 = Buffer.from(
        base64String as WithImplicitCoercion<string>,
        "base64"
      ).toString("utf-8") as string
      const id = decodedBase64 && decodedBase64.split("&")[0]?.split("=")[1]
      replace(`/detail/${id}`)
    } else if (id) {
      replace(`/detail/${id}`)
    } else {
      if (session && !isForcedAuth) {
        if (session?.provider_refresh_token) {
          const created_at = session.user.created_at
          // Redirection logic: If the user created their account today, we redirect them to the provided meetings tab.
          // This is done to guide new users on how to use the app and help them get started with their first meetings.
          // If the user's account was not created today, we simply redirect them to the dashboard.
          if (created_at) {
            const date = new Date(created_at)
            const now = new Date()
            const diffDays = differenceInCalendarDays(now, date)

            if (diffDays < 1) {
              replace("/dashboard?tab=providedmeetings")
              return
            }
          }
        }

        replace("/dashboard")
      } else {
        replace("/sign-in?v=1")
      }
    }
  }, [id, isLoading, params, profile, replace, session, supabase.auth])

  return <Loading />
}

export default Login
