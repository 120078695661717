import * as React from "react"
import Image from "next/image"

const Loading = () => {
  return (
    <div className="absolute z-50 flex h-screen w-full items-center justify-center bg-opacity-75">
      <div className="relative h-28 w-28">
        <div className="relative h-28 w-28 animate-spin rounded-full border-b-4 border-t-4 border-purple-500" />
        <Image
          className="absolute left-[21px] top-[47px] z-10 animate-none"
          src="/assets/logo.svg"
          width={70}
          height={70}
          alt="Huudle"
        />
      </div>
    </div>
  )
}

export default Loading
